<template>
  <div>
    <h1>List of Resources</h1>
    <h3>Work is in progress...</h3>
    <!-- <v-card class="elevation-4 pa-6">
			<v-treeview
				dense
				activatable
				:items="items"
				:active="activeItems"
				:open="openedItems"
				@update:active="onActive"
				@update:open="onOpen"
				item-key="id"
				item-text="name"
			>
				<template v-slot:label="{ item }"> -->
    <!-- <v-icon x-small v-if="isNodeLeaf(item.id)">mdi-edit</v-icon> -->
    <!-- <v-icon v-else>{{ files[item.file] }}</v-icon> -->
    <!-- <v-btn small text color="primary" v-if="isNodeLeaf(item.id)" style="padding: 0px">
						<v-icon x-small style="paddingLeft: 5px">mdi-edit</v-icon>
						{{ item.name }}
					</v-btn>
					<div class="ma-2" v-if="!isNodeLeaf(item.id)">{{ item.name }}</div>
				</template>
			</v-treeview>
		</v-card> -->
  </div>
</template>

<script>
import axios from "axios";
const dnaData = null; //require('@/dummy-data/dna.json')

export default {
  name: "Resources",
  components: {},
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
      validator() {
        return true;
      }
    }
  },
  data() {
    return {
      items: dnaData,
      openedItems: [],
      activeItems: []
    };
  },

  methods: {
    init: function() {
      this.openFirstLevel();
    },
    openFirstLevel() {
      if (this.items == null || !Array.isArray(this.items)) return;
      if (this.items.length != 1) return;
      const rootItem = this.items[0];
      const firstLevelIds = rootItem.children.map(p => p.id);
      this.openedItems = [];
      this.openedItems.push(rootItem.id);
      this.openedItems.push(...firstLevelIds);
    },
    onOpen: function(event) {
      this.openedItems = event;
    },
    onActive: function(event) {
      this.activeItems = event;
      if (this.activeItems.length != 1) return;
      this.onClick(this.activeItems[0]);
    },
    onClick(nodeId) {
      if (this.isNodeLeaf(nodeId)) {
        const resid = nodeId;
        this.$router.push({ path: `/resources/` + resid + `/` });
      }
    },
    findNodeById(nodeId) {
      if (this.items.length != 1) return null;
      return this.findNodeByIdReq(nodeId, this.items[0]);
    },
    findNodeByIdReq(nodeId, parentNode) {
      if (parentNode.id == nodeId) return parentNode;
      for (const childNode of parentNode.children) {
        const node = this.findNodeByIdReq(nodeId, childNode);
        if (node != null) return node;
      }
    },
    isNodeLeaf(nodeId) {
      const node = this.findNodeById(nodeId);
      if (node == null) return false;
      if (node.children == null) return false;
      if (node.children.length != 0) return false;
      return true;
    }
  },
  mounted: function() {
    this.$nextTick(function() {
      this.init();
    });
  }
};
</script>

<style lang="scss" scoped></style>
